.header {
    $h: &;
    @include trans;

    position: fixed;
    width: 100%;
    z-index: 1000;
    height: 10vh;

    &.-scroll-change-color {
        background: $red;
    }

    &__container {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;

        @include mq(md) {
            justify-content: space-between;
        }
    }

    &__logo {
        @include trans;
        height: 80%;
        z-index: 2000;

        img {
            width: auto;
            height: 100%;
        }

        &.-scroll-change-color {
            filter: brightness(1500%);
        }

        &.-nav-change-color {
            filter: brightness(1500%);
        }
    }

    &__nav {
        @include trans;

        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        height: 100vh;
        left: 0;
        transform: translateX(-100%);
        opacity: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq(md) {
            position: relative;
            opacity: 1;
            transform: translateY(0);
            justify-content: flex-end;
            height: auto;
        }

        &.-open {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__list {
        list-style: none;

        @include mq(md) {
            display: flex;
        }

        li {
            &:not(:last-child) {
                margin-bottom: 20px;

                @include mq(sm) {
                    margin-bottom: 0;
                }
            }

            @include mq(sm) {
                margin-left: 20px;
            }
        }

        a {
            text-transform: uppercase;
            color: $white;
            font-weight: 900;
            font-size: 16px;

            @include mq(sm) {
                font-size: 14px;
            }
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 25px;
        top: 22px;

        @include mq(md) {
            display: none;
        }

        span {
            background-color: $black;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &__language {
        height: 60px;
        border: none;
        width: 95%;
        padding-left: 5px;
        color: $white;
        font-size: 14px;
        font-weight: 500;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $mainColor url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC') no-repeat 100% 50%;
        padding-right:20px;
        text-transform: uppercase;

        @include mq(md) {
            height: 30px;
            border: 1px solid $lightGray;
            color: $mainColor;
            width: 60px;
            background: $white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC') no-repeat 90% 50%;
            transform: translateY(4px);
            margin-left: 20px;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
            background: $red;
            opacity: 1;
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
